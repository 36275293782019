import { useState } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import Divider from '../../../components/Divider';

const RightSideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 10vw;
  height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightSideList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  height: 60vh;
  position: fixed;

  span {
    color: #adff2f;
    font-family: monospace;
    letter-spacing: 0.1rem;
  }
`;

const Email = styled.p`
  writing-mode: vertical-lr;
  text-orientation: mixed;
  color: lightgray;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: color 300ms;
  margin-bottom: 2rem;
  margin-top: 0.5rem;

  :hover {
    color: #adff2f;
    transition: color 300ms;
  }
`;
const RightSideBar = () => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <RightSideBarWrapper>
      <RightSideList>
        {isCopied && (
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(50)
                .typeString('Copied!')
                .pauseFor(2500)
                .deleteAll()
                .start();
            }}
            options={{
              autoStart: false,
            }}
          />
        )}
        <Email
          onClick={(event) => {
            event.preventDefault();
            navigator.clipboard.writeText('philiprutberg00@gmail.com');
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 5000);
          }}
        >
          philiprutberg00@gmail.com
        </Email>
        <Divider vertical={true} />
      </RightSideList>
    </RightSideBarWrapper>
  );
};

export default RightSideBar;
