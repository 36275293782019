import styled from 'styled-components';
import Button from '../components/Button';
import { checkTheBorgir } from './Hamburger';
import { Icons } from '../constants/icons.enum';
import { downloadFile } from '../utils/functions.utils';
import FadeInTop from './motion/FadeInTop';
import { scrollTo } from '../utils/navigation.utils';

const SpanItemSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SpanItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 2rem;
  cursor: pointer;
  user-select: none;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #adff2f;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  :hover::after,
  :focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }
`;

const Navigation = () => {
  return (
    <SpanItemSection>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.5}>
        <SpanItem
          onClick={() => {
            checkTheBorgir(true);
            scrollTo('landing');
          }}
        >
          Home
        </SpanItem>
      </FadeInTop>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.6}>
        <SpanItem
          onClick={() => {
            checkTheBorgir(true);
            scrollTo('about-me');
          }}
        >
          About
        </SpanItem>
      </FadeInTop>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.7}>
        <SpanItem
          onClick={() => {
            checkTheBorgir(true);
            scrollTo('skills');
          }}
        >
          Skills
        </SpanItem>
      </FadeInTop>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.8}>
        <SpanItem
          onClick={() => {
            checkTheBorgir(true);
            scrollTo('contact');
          }}
        >
          Contact
        </SpanItem>
      </FadeInTop>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.9}>
        <Button
          onClick={downloadFile}
          text='Resume'
          icon={Icons.Download}
          theme='dark'
        />
      </FadeInTop>
    </SpanItemSection>
  );
};

export default Navigation;
