export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const scrollTo = (className: string) => {
  if (!className.startsWith('.')) {
    className = `.${className}`;
  }
  const component = document.querySelector(className) as Element;
  component.scrollIntoView({ behavior: 'smooth' });
};
