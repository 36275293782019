import { useState } from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg<{ hovering: boolean }>`
  transition: all 0.2s ease-in-out;
  path {
    transition: all 0.2s ease-in-out;
  }
`;

const Figma = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledSvg
      width='64px'
      height='64px'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isHovered ? 1 : 0.1 }}
      hovering={isHovered}
    >
      <g>
        <path
          fill={isHovered ? '#1ABCFE' : 'darkgray'}
          d='M8.55 8c0-1.289 1.019-2.333 2.275-2.333C12.082 5.667 13.1 6.71 13.1 8c0 1.289-1.018 2.333-2.275 2.333C9.57 10.333 8.55 9.29 8.55 8z'
        />
        <path
          fill={isHovered ? '#0ACF83' : 'darkgray'}
          d='M4 12.667c0-1.289 1.019-2.334 2.275-2.334H8.55v2.334C8.55 13.955 7.531 15 6.275 15S4 13.955 4 12.667z'
        />
        <path
          fill={isHovered ? '#FF7262' : 'lightgray'}
          d='M8.55 1v4.667h2.275c1.257 0 2.275-1.045 2.275-2.334C13.1 2.045 12.082 1 10.825 1H8.55z'
        />
        <path
          fill={isHovered ? '#F24E1E' : 'darkgray'}
          d='M4 3.333c0 1.289 1.019 2.334 2.275 2.334H8.55V1H6.275C5.019 1 4 2.045 4 3.333z'
        />
        <path
          fill={isHovered ? '#A259FF' : 'lightgray'}
          d='M4 8c0 1.289 1.019 2.333 2.275 2.333H8.55V5.667H6.275C5.019 5.667 4 6.71 4 8z'
        />
      </g>
    </StyledSvg>
  );
};

export default Figma;
