import { useState } from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transition: all 0.2s ease-in-out;
  path,
  polygon {
    transition: all 0.2s ease-in-out;
  }
`;

const Firebase = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledSvg
      width='64px'
      height='64px'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isHovered ? 1 : 0.1 }}
    >
      <g>
        <path
          d='M5.8,24.6l.17-.237L13.99,9.149l.017-.161L10.472,2.348a.656.656,0,0,0-1.227.207Z'
          fill={isHovered ? '#ffc24a' : 'lightgray'}
        />
        <path
          d='M5.9,24.42l.128-.25L13.965,9.114,10.439,2.448a.6.6,0,0,0-1.133.206Z'
          fill={isHovered ? '#ffa712' : 'darkgray'}
        />
        <path
          d='M16.584,14.01l2.632-2.7L16.583,6.289a.678.678,0,0,0-1.195,0L13.981,8.971V9.2Z'
          fill={isHovered ? '#f4bd62' : 'lightgray'}
        />
        <path
          d='M16.537,13.9l2.559-2.62L16.537,6.4a.589.589,0,0,0-1.074-.047L14.049,9.082l-.042.139Z'
          fill={isHovered ? '#ffa50e' : 'darkgray'}
        />
        <polygon
          points='5.802 24.601 5.879 24.523 6.158 24.41 16.418 14.188 16.548 13.834 13.989 8.956 5.802 24.601'
          fill={isHovered ? '#f6820c' : 'darkgray'}
        />
        <path
          d='M16.912,29.756,26.2,24.577,23.546,8.246A.635.635,0,0,0,22.471,7.9L5.8,24.6l9.233,5.155a1.927,1.927,0,0,0,1.878,0'
          fill={isHovered ? '#fde068' : 'lightgray'}
        />
        <path
          d='M26.115,24.534,23.483,8.326a.557.557,0,0,0-.967-.353L5.9,24.569l9.131,5.1a1.912,1.912,0,0,0,1.863,0Z'
          fill={isHovered ? '#fcca3f' : 'darkgray'}
        />
        <path
          d='M16.912,29.6a1.927,1.927,0,0,1-1.878,0L5.876,24.522,5.8,24.6l9.233,5.155a1.927,1.927,0,0,0,1.878,0L26.2,24.577l-.023-.14Z'
          fill={isHovered ? '#eeab37' : 'darkgray'}
        />
      </g>
    </StyledSvg>
  );
};

export default Firebase;
