import styled from 'styled-components';
import Profile from '../../../assets/images/profile.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: fit-content;
  position: relative;
`;

const CardBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 350px;
  background-color: transparent;
  border-radius: 5px;
  border: 5px solid #adff2f;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-left: 40px;
  transition: all 0.2s ease-in-out;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 350px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  user-select: none;

  img {
    object-fit: contain;
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;
  }

  :hover {
    transform: translate(-10px, -10px);
    transition: all 0.2s ease-in-out;
    img {
      filter: grayscale(0%);
      transition: all 0.2s ease-in-out;
    }
  }
`;

const Container = styled.div`
  width: auto;
  height: auto;

  &:hover ${CardBackground} {
    opacity: 0.2;
    transition: all 0.2s ease-in-out;
  }
`;
const ProfilePicture = () => {
  return (
    <Wrapper>
      <Container>
        <Card>
          <img
            src={Profile}
            alt='profile'
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </Card>
        <CardBackground />
      </Container>
    </Wrapper>
  );
};

export default ProfilePicture;
