import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import LeftSideBar from './components/LeftSideBar';
import RightSideBar from './components/RightSideBar';
import { openInNewTab } from '../../utils/navigation.utils';
import { Hyperlinks } from '../../constants/hyperlinks.enum';
import FadeInBottom from '../../components/motion/FadeInBottom';
import { Icons } from '../../constants/icons.enum';
import Button from '../../components/Button';
import { downloadFile } from '../../utils/functions.utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: auto;
  scroll-snap-align: start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 64px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  width: 60vw;

  @media (max-width: 768px) {
    align-items: flex-start;
    width: 90vw;
  }
`;

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100vw;

  @media (max-width: 768px) {
    width: 100vw;
    align-items: center;
    justify-content: center;
    & > div,
    & > h1,
    & > p {
      margin: 1rem;
    }
  }
`;

const IntroductionContainer = styled.div`
  display: inline-block;
`;

const IntroductionName = styled.h2`
  color: lightgray;
  overflow: hidden;
  border-right: 0.15em solid #adff2f;
  white-space: nowrap;
  animation: typing 2s steps(20, end) forwards,
    blink-caret 0.5s step-end infinite;
  font-family: monospace;
  margin: 0 auto;
  width: max-content;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #adff2f;
    }
  }

  font-size: clamp(1rem, 2vw, 1.5rem);
`;

const GreenText = styled.span`
  color: #adff2f;
`;

const PostNordBlueText = styled.span`
  display: inline-block;
  position: relative;
  color: #01a0d7;
  cursor: pointer;

  :after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #01a0d7;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  :hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const IntroductionDescription = styled.h1`
  color: lightgray;
  font-size: clamp(1.5rem, 4vw, 3rem);
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const ParagraphText = styled.p`
  color: lightgray;
  max-width: 50vw;
  font-size: clamp(0.8rem, 2vw, 1.2rem);

  @media (max-width: 768px) {
    max-width: 90vw;
  }
`;

function Landing({ className }: { className?: string }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper className={className}>
      <LeftSideBar />
      <LandingContainer>
        <TextContainer>
          <IntroductionContainer>
            <IntroductionName>
              Hi, my name is <GreenText>P</GreenText>hilip{' '}
              <GreenText>R</GreenText>utberg!
            </IntroductionName>
          </IntroductionContainer>
          <FadeInBottom
            style={{ width: '100%', height: '100%' }}
            delay={2.5}
            startPos={20}
            duration={0.5}
            showOnce={true}
          >
            <IntroductionDescription>
              I'm a <GreenText>Fullstack</GreenText>
              <br />
              Software Engineer.
              <br />I build stunning web solutions.
            </IntroductionDescription>
          </FadeInBottom>
          <FadeInBottom
            style={{ width: '100%', height: '100%' }}
            delay={2.7}
            startPos={20}
            duration={0.5}
            showOnce={true}
          >
            <ParagraphText>
              I'm a Software Engineer with a passion for building fullstack
              solutions.
              {!isTabletOrMobile && <br />}
              I'm currently working at{' '}
              <PostNordBlueText
                onClick={() => openInNewTab(Hyperlinks.POSTNORD)}
              >
                <strong>PostNord</strong>
              </PostNordBlueText>{' '}
              as a Software Engineer.
            </ParagraphText>
          </FadeInBottom>
          <FadeInBottom
            delay={2.9}
            startPos={20}
            duration={0.5}
            showOnce={true}
          >
            <Button
              onClick={downloadFile}
              text='Resume'
              icon={Icons.Download}
              theme='dark'
              style={{ margin: '1rem 0 1rem 0' }}
            />
          </FadeInBottom>
        </TextContainer>
      </LandingContainer>
      <RightSideBar />
    </Wrapper>
  );
}

export default Landing;
