import styled from 'styled-components';
import { ReactComponent as Logotype } from '../assets/images/logo.svg';
import { scrollTo } from '../utils/navigation.utils';

const StyledLogo = styled(Logotype)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin-left: 3rem;

  @media (min-width: 768px) {
    :hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  }
`;
const Logo = () => {
  return (
    <StyledLogo
      onClick={() => {
        scrollTo('landing');
      }}
    />
  );
};

export default Logo;
