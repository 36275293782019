import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaCloudDownloadAlt, FaPaperPlane } from 'react-icons/fa';
import { Icons } from '../constants/icons.enum';

const ButtonWrapper = styled.div<{
  theme: 'dark' | 'light';
  iconRight?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.theme === 'dark' ? 'none' : '#adff2f')};
  border: 1px solid #adff2f;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 2rem;
  border-radius: 0.3rem;
  transition: 0.2s;

  :hover {
    background: ${(props) => (props.theme === 'dark' ? '#adff2f' : '#0a192f')};
    color: ${(props) => (props.theme === 'dark' ? '#0a192f' : '#adff2f')};
    transition: 0.2s;
    cursor: pointer;
  }

  & > svg {
    ${(props) =>
      props.iconRight ? 'margin-left: 0.5rem;' : 'margin-right: 0.5rem'}
  }
`;

const getIcon = (icon: Icons) => {
  switch (icon) {
    case Icons.Download:
      return <FaCloudDownloadAlt />;
    case Icons.PaperPlane:
      return <FaPaperPlane />;
    case Icons.Email:
      return <FaEnvelope />;
  }
};

interface ButtonProps {
  onClick: (e: any) => void;
  icon?: Icons;
  iconRight?: boolean;
  theme?: 'dark' | 'light';
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

function Button({
  onClick,
  text,
  icon,
  iconRight,
  theme,
  className,
  style,
}: ButtonProps) {
  return (
    <ButtonWrapper
      className={className || ''}
      onClick={onClick}
      iconRight={iconRight}
      theme={theme}
      style={style}
    >
      {icon && !iconRight && getIcon(icon)}
      {text}
      {icon && iconRight && getIcon(icon)}
    </ButtonWrapper>
  );
}

export default Button;
