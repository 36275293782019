import { promisify } from 'util';
import { saveAs } from 'file-saver';

export const sleep = promisify(setTimeout);

export const emailValid = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const downloadFile = () => {
  const resume = require('../assets/files/Philip_Rutberg_Resume.pdf');
  saveAs(resume, 'Philip_Rutberg_Resume.pdf');
};

export const navBarDisappearOnScroll = (isTabletOrMobile: boolean) => {
  let prevScrollpos = window.scrollY;
  window.onscroll = () => {
    let currentScrollPos = window.scrollY;
    if (isTabletOrMobile) {
      if (prevScrollpos > currentScrollPos || window.scrollY <= 25) {
        const sidebarTop = document.querySelector(
          '.sidebar-top'
        ) as HTMLElement;
        if (!sidebarTop) return;
        sidebarTop.style.top = '0';
        sidebarTop.style.boxShadow = '0px 15px 40px -15px #111';
        sidebarTop.style.background = 'rgba(10, 25, 47, 0.8)';
        if (window.scrollY <= 10) {
          sidebarTop.style.boxShadow = 'none';
          sidebarTop.style.background = 'rgba(10, 25, 47)';
        }
      } else {
        const sidebarTop = document.querySelector(
          '.sidebar-top'
        ) as HTMLElement;
        if (!sidebarTop) return;
        sidebarTop.style.top = '-10vh';
        sidebarTop.style.boxShadow = 'none';
      }
    } else {
      const topBar = document.querySelector('.top-bar') as HTMLElement;
      if (!topBar) return;
      if (prevScrollpos > currentScrollPos || window.scrollY <= 25) {
        topBar.style.top = '0';
        topBar.style.boxShadow = '0px 15px 40px -15px #111';
        topBar.style.background = 'rgba(10, 25, 47, 0.8)';
        if (window.scrollY <= 10) {
          topBar.style.boxShadow = 'none';
          topBar.style.background = 'rgba(10, 25, 47)';
        }
      } else {
        topBar.style.top = '-10vh';
        topBar.style.boxShadow = 'none';
      }
    }
    prevScrollpos = currentScrollPos;
  };
};
