import React, { useEffect, useState } from 'react';

interface Props {
  time: number;
  children: React.ReactNode;
}

const Unscrollable = ({ time, children }: Props) => {
  const [scrollable, setScrollable] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      setScrollable(true);
    }, time);

    return () => {
      setScrollable(false);
      document.body.style.overflow = 'auto';
    };
  }, [time]);

  return scrollable ? <>{children}</> : null;
};

export default Unscrollable;
