import styled from 'styled-components';

function openNav() {
  const sideBar = document.querySelector('.sideBar') as HTMLElement;
  sideBar.style.width = '100%';
  const profilePicContainer = document.querySelector(
    '.profile-pic-container'
  ) as HTMLElement;
  if (profilePicContainer) {
    profilePicContainer.style.zIndex = '-1';
  }
  const body = document.body;
  body.style.margin = '0';
  body.style.height = '100%';
  body.style.overflow = 'hidden';
}

function closeNav() {
  const sideBar = document.querySelector('.sideBar') as HTMLElement;
  sideBar.style.width = '0';

  const profilePicContainer = document.querySelector(
    '.profile-pic-container'
  ) as HTMLElement;
  if (profilePicContainer) {
    setTimeout(() => {
      profilePicContainer.style.zIndex = '';
    }, 500);
  }

  const body = document.body;
  body.style.margin = '';
  body.style.height = '';
  body.style.overflow = '';
}

export function checkTheBorgir(shouldAlsoResetBorgir?: boolean) {
  const borgir = document.querySelector('.borgir');
  if (!borgir) return;
  borgir.classList.toggle('active');
  borgir.classList.contains('active') ? openNav() : closeNav();
  if (shouldAlsoResetBorgir) {
    (borgir as HTMLInputElement).checked = false;
  }
}

const Hamburger = styled.label`
  input {
    display: none;
  }
  @media (max-width: 768px) {
    display: block;
    width: 40px;
    height: 36px;
    position: relative;
    cursor: pointer;
    transform: rotate(180deg) rotateX(180deg);
    -webkit-transform: translate3d(0, 0, 0);

    div {
      position: absolute;
      height: 4px;
      border-radius: 2px;
      background: #adff2f;
      transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6),
        width 0.2s ease 0.2s;
    }
    input {
      display: none;
      & + div {
        top: 50%;
        left: 0;
        margin: -2px 0 0 0;
        width: 40px;
        transform-origin: 50% 50%;
        & + div {
          top: 2px;
          left: 0;
          width: 20px;
          transform-origin: 0 50%;
          & + div {
            bottom: 2px;
            right: 0;
            width: 28px;
            transform-origin: 100% 50%;
            transform: translate(-12px, 0);
          }
        }
      }
      &:checked + div {
        transform: rotate(-45deg);
        transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;
        & + div {
          width: 19px;
          transform: translate(6px, 0) rotate(45deg);
          transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s,
            width 0.2s ease;
          & + div {
            width: 19px;
            transform: translate(-6px, 0) rotate(45deg);
            transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s,
              width 0.2s ease;
          }
        }
      }
    }
  }
`;
const Sidebar = () => {
  return (
    <Hamburger>
      <input
        className='borgir'
        type='checkbox'
        onChange={() => {
          checkTheBorgir();
        }}
      />
      <div></div>
      <div></div>
      <div></div>
    </Hamburger>
  );
};

export default Sidebar;
