import styled from 'styled-components';

const SpacerContainer = styled.div<{ height?: string; multiplier?: number }>`
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => (props.multiplier ? props.multiplier * 1 : 1)}rem;
  opacity: 0;
`;

interface SpacerProps {
  height?: string;
  multiplier?: number;
}

function Spacer({ height, multiplier }: SpacerProps) {
  return <SpacerContainer height={height} multiplier={multiplier} />;
}

export default Spacer;
