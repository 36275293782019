import styled from 'styled-components';
import Navigation from '../../components/Navigation';
import Hamburger from '../../components/Hamburger';
import Logo from '../../components/Logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 10vh;
  background: rgba(10, 25, 47, 0.8);
  position: fixed;
  z-index: 100;
  transition: all 0.5s;
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
`;

const LogoAndBurger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
  z-index: 10;

  svg {
    margin: 0;
    margin-left: 1.5rem;
    left: 0;
    position: fixed;
  }
  label {
    margin-right: 1.5rem;
    position: fixed;
    right: 0;
  }
`;

const NavWrapper = styled.div`
  height: 100vh;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  right: 0;
  top: 0;
  position: fixed;
  overflow: hidden;
  transition: 0.5s;
  background-color: #0a192f;
  -webkit-transform: translate3d(0, 0, 0);

  & > div {
    margin-bottom: 5rem;
  }
  & > div > div {
    margin: 0;
    margin-bottom: 2rem;
  }
  & > div > span {
    margin: 0;
    margin-bottom: 2rem;
  }
`;

const Sidebar = () => {
  return (
    <Wrapper className='sidebar-top'>
      <LogoAndBurger>
        <Logo />
        <Hamburger />
      </LogoAndBurger>
      <NavWrapper className='sideBar'>
        <Navigation />
      </NavWrapper>
    </Wrapper>
  );
};

export default Sidebar;
