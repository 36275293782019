import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FaUser, FaEnvelope } from 'react-icons/fa';
import { emailValid } from '../utils/functions.utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
`;

const Label = styled.label`
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: lightgray;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.p<{
  errorMessage: string;
  value: string;
  type: string;
}>`
  font-size: 0.8rem;
  color: red;
  visibility: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0;
  input:focus + & {
    visibility: ${(props) =>
      (isEmailFieldValidation(props.type)
        ? !emailValid(props.value)
        : props.value.length === 0) && props.errorMessage
        ? 'visible'
        : 'hidden '};
  }
`;

const InputWrapper = styled.div<{
  errorMessage: string;
  value: string;
  type: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  :focus-within {
    & + ${ErrorMessage} {
      visibility: ${(props) =>
        (isEmailFieldValidation(props.type)
          ? !emailValid(props.value)
          : props.value.length === 0) && props.errorMessage
          ? 'visible'
          : 'hidden'};
    }
  }
`;

const InputElement = styled.input<{
  icon: string | undefined;
  value: string;
  errorMessage: string;
  type: string;
}>`
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 0.3rem;
  height: 1.5rem;
  background-color: #0a192f;
  padding: 0.5rem;
  width: -webkit-fill-available;
  ${(props) => (props.icon ? 'padding-left: 2rem;' : '')}

  :focus {
    outline: none;
    border: ${(props) =>
      (isEmailFieldValidation(props.type)
        ? !emailValid(props.value)
        : props.value.length === 0) && props.errorMessage
        ? '1px solid red'
        : '1px solid #adff2f'};
  }
`;

const TextAreaElement = styled.textarea<{
  value: string;
  errorMessage: string;
}>`
  font-size: 1rem;
  font-weight: bold;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 0.3rem;
  height: 8rem;
  background-color: #0a192f;
  padding: 0.5rem;
  width: -webkit-fill-available;
  resize: none;

  :focus {
    outline: none;
    border: ${(props) =>
      props.value.length === 0 && props.errorMessage
        ? '1px solid red'
        : '1px solid #adff2f'};
  }
`;

const UserIcon = styled(FaUser)`
  color: lightgray;
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  margin-left: 0.5rem;
  opacity: 0.8;
`;

const EmailIcon = styled(FaEnvelope)`
  color: lightgray;
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  margin-left: 0.5rem;
  opacity: 0.8;
`;

const getIcon = (icon: string | undefined) => {
  switch (icon) {
    case 'user':
      return <UserIcon />;
    case 'email':
      return <EmailIcon />;
  }
};

function isEmailFieldValidation(type: string): boolean {
  return type === 'email';
}

interface InputProps {
  label?: string;
  placeholder?: string;
  icon?: string;
  errorMessage?: string;
  type: 'text' | 'email' | 'textarea';
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<InputProps> = ({
  label,
  placeholder,
  icon,
  errorMessage = '',
  type,
  value,
  onChange,
}) => {
  return (
    <Wrapper className={`${type === 'textarea' ? 'text-area' : ''}`}>
      {label && <Label>{label}</Label>}
      <InputWrapper type={type} value={value} errorMessage={errorMessage}>
        {icon && getIcon(icon)}
        {type === 'textarea' ? (
          <TextAreaElement
            errorMessage={errorMessage}
            value={value}
            // @ts-ignore
            onChange={onChange}
          />
        ) : (
          <InputElement
            errorMessage={errorMessage}
            icon={icon}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
          />
        )}
      </InputWrapper>
      {errorMessage && (
        <ErrorMessage type={type} value={value} errorMessage={errorMessage}>
          {errorMessage}
        </ErrorMessage>
      )}
    </Wrapper>
  );
};

export default Input;
