import { useMediaQuery } from 'react-responsive';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import Unscrollable from './components/Unscrollable';

import Sidebar from './containers/Sidebar';
import TopBar from './containers/Topbar';
import AboutMe from './pages/aboutme/AboutMe';
import Contact from './pages/contact/Contact';
import Landing from './pages/landing/Landing';
import Skills from './pages/skills/Skills';

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BrowserRouter>
      {isDesktopOrLaptop && <TopBar />}
      {isTabletOrMobile && <Sidebar />}
      <Wrapper>
        <Landing className='landing' />
        <Unscrollable time={3000}>
          <AboutMe className='about-me' />
          <Skills className='skills' />
          <Contact className='contact' />
        </Unscrollable>
      </Wrapper>
    </BrowserRouter>
  );
};

export default App;
