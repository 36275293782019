import React from 'react';
import styled from 'styled-components';

const DividerComponent = styled.div<{
  vertical?: boolean;
  height?: string;
  width?: string;
  color: string;
}>`
  width: ${(props) => props.width || (props.vertical ? '1px' : '15vw')};
  height: ${(props) => props.height || (props.vertical ? '15vh' : '1px')};
  background-color: ${(props) => props.color};
`;

interface DivierProps extends React.HTMLAttributes<HTMLDivElement> {
  vertical?: boolean;
  height?: string;
  width?: string;
  color?: string;
}

const Divider = ({
  vertical,
  height,
  width,
  color = '#adff2f',
  ...props
}: DivierProps) => {
  return (
    <DividerComponent
      vertical={vertical}
      height={height}
      width={width}
      color={color}
      {...props}
    />
  );
};

export default Divider;
