import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children?: JSX.Element[] | JSX.Element;
  duration?: number;
  delay?: number;
  startPos?: number;
  showOnce?: boolean;
  animateOnLoad?: boolean;
  style?: React.CSSProperties;
}

const FadeInTop = ({
  children,
  duration = 2,
  delay = 0,
  startPos = -50,
  showOnce = false,
  animateOnLoad = true,
  style,
}: Props) => {
  return (
    <motion.div
      style={style}
      initial={{ opacity: 0, y: startPos }}
      animate={!animateOnLoad && { opacity: 1, y: 0 }}
      transition={{ duration: duration, delay: delay }}
      whileInView={(animateOnLoad && { opacity: 1, y: 0 }) || undefined}
      viewport={{ once: showOnce }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInTop;
