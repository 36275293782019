import styled from 'styled-components';
import { useState } from 'react';
import Input from '../../components/Input';
import Typewriter from 'typewriter-effect';
import { FaEnvelope } from 'react-icons/fa';
import Divider from '../../components/Divider';
import FadeInLeft from '../../components/motion/FadeInLeft';
import FadeInBottom from '../../components/motion/FadeInBottom';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  min-height: 100vh;
  scroll-snap-align: start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 64px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  height: 60vh;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
`;

const Form = styled.form`
  width: 25vw;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr) 6fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  span {
    color: #adff2f;
    font-family: monospace;
    letter-spacing: 0.1rem;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 25vw;
  height: 100%;

  .send-button {
    margin: 1rem 0 0 0;
  }

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const ContactText = styled.h1`
  font-size: 1.5rem;
  color: #adff2f;
  font-weight: bold;
  font-family: monospace;
`;

const ContactAndDivider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const ContactDescription = styled.p`
  font-size: 2rem;
  color: lightgray;
  font-weight: bold;
`;

const ContactTextBottom = styled.p`
  color: darkgray;
`;

const Email = styled.p`
  color: lightgray;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 300ms;

  :hover {
    color: #adff2f;
    transition: color 300ms;
  }
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const EmailIcon = styled(FaEnvelope)`
  color: lightgray;
  margin-right: 0.5rem;
`;

const Contact = ({ className }: { className?: string }) => {
  const [from, setFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 769px)',
  });

  return (
    <Wrapper className={className}>
      <ContactContainer>
        <Left>
          <FadeInLeft
            style={{ width: '100%' }}
            duration={0.5}
            showOnce
            startPos={50}
          >
            <ContactAndDivider>
              <ContactText>Contact</ContactText>
              <Divider width={isDesktopOrLaptop ? '50%' : '100%'} />
            </ContactAndDivider>
          </FadeInLeft>
          {isDesktopOrLaptop && (
            <FadeInBottom duration={0.5} startPos={50} showOnce>
              <ContactDescription>
                Found my profile interesting?
              </ContactDescription>
              <ContactTextBottom>
                Let's chat! I'd love to hear from you. Whether you have a
                question, want to collaborate on a project, or just feel like
                saying hello, don't hesitate to drop me a line at:
              </ContactTextBottom>
              {isCopied ? (
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .changeDelay(50)
                      .typeString('Copied!')
                      .pauseFor(2000)
                      .deleteAll()
                      .start();
                  }}
                  options={{
                    autoStart: false,
                  }}
                />
              ) : (
                <></>
              )}
              <EmailWrapper>
                <EmailIcon />
                <Email
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText('philiprutberg00@gmail.com');
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 5000);
                  }}
                >
                  philiprutberg00@gmail.com
                </Email>
              </EmailWrapper>
            </FadeInBottom>
          )}
        </Left>
        <Right>
          <Form>
            <FadeInBottom duration={0.5} startPos={50} showOnce>
              <Input
                type='text'
                value={subject}
                icon='user'
                label='Name'
                errorMessage='Name cannot be empty'
                onChange={(e) => {
                  e.preventDefault();
                  setSubject(e.target.value);
                }}
              />
            </FadeInBottom>
            <FadeInBottom duration={0.5} startPos={50} showOnce>
              <Input
                type='email'
                value={from}
                icon='email'
                label='Email'
                errorMessage='Invalid email adress'
                onChange={(e) => {
                  e.preventDefault();
                  setFrom(e.target.value);
                }}
              />
            </FadeInBottom>
            <FadeInBottom duration={0.5} startPos={50} showOnce>
              <Input
                type='textarea'
                value={message}
                label='Message'
                errorMessage='Message cannot be empty'
                onChange={(e) => {
                  e.preventDefault();
                  setMessage(e.target.value);
                }}
              />
            </FadeInBottom>
            <FadeInBottom duration={0.5} startPos={50} showOnce>
              {/* <Button
                onClick={(e) => {
                  HttpService.post({
                    url: 'http://localhost:3001/sendMail',
                    body: {
                      from,
                      subject,
                      message,
                    },
                  });
                }}
                text='Send'
                icon={Icons.PaperPlane}
                iconRight={true}
                theme={'dark'}
                className='send-button'
              /> */}
              <p>Contact coming soon!</p>
            </FadeInBottom>
          </Form>
        </Right>
      </ContactContainer>
    </Wrapper>
  );
};

export default Contact;
