import styled from 'styled-components';
import Logo from '../../components/Logo';
import FadeInTop from '../../components/motion/FadeInTop';
import Navigation from '../../components/Navigation';

const TopBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  background: rgba(10, 25, 47, 0.8);
  z-index: 100;
  height: 64px;
  transition: all 0.5s;
  position: fixed;
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
`;

const TopBarNavigationSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 3rem;
`;

const Topbar = () => {
  return (
    <TopBarContainer className='top-bar'>
      <FadeInTop showOnce animateOnLoad={false} duration={0.5} delay={2.4}>
        <Logo />
      </FadeInTop>
      <TopBarNavigationSection>
        <Navigation />
      </TopBarNavigationSection>
    </TopBarContainer>
  );
};

export default Topbar;
