import styled from 'styled-components';
import { FaGithub, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import Divider from '../../../components/Divider';
import { openInNewTab } from '../../../utils/navigation.utils';
import { Hyperlinks } from '../../../constants/hyperlinks.enum';

const LeftSideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 10vw;
  height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftSideList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  height: 60vh;
  position: fixed;

  svg {
    margin-bottom: 2rem;
  }
`;

const GithubIcon = styled(FaGithub)`
  color: lightgray;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 300ms, transform 300ms;

  :hover {
    color: #adff2f;
    transform: translate3d(0, -0.1em, 0);
    transition: color 300ms, transform 300ms;
  }
`;

const LinkedinIcon = styled(FaLinkedinIn)`
  color: lightgray;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 300ms, transform 300ms;

  :hover {
    color: #adff2f;
    transform: translate3d(0, -0.1em, 0);
    transition: color 300ms, transform 300ms;
  }
`;

const InstagramIcon = styled(FaInstagram)`
  color: lightgray;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 300ms, transform 300ms;

  :hover {
    color: #adff2f;
    transform: translate3d(0, -0.1em, 0);
    transition: color 300ms, transform 300ms;
  }
`;
const LeftSideBar = () => {
  return (
    <LeftSideBarWrapper>
      <LeftSideList>
        <GithubIcon onClick={() => openInNewTab(Hyperlinks.GITHUB)} />
        <LinkedinIcon onClick={() => openInNewTab(Hyperlinks.LINKEDIN)} />
        <InstagramIcon onClick={() => openInNewTab(Hyperlinks.INSTAGRAM)} />
        <Divider vertical={true} />
      </LeftSideList>
    </LeftSideBarWrapper>
  );
};

export default LeftSideBar;
