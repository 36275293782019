import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  overflow: hidden;
  @keyframes slideAnim {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

const InnerList = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;

  animation: slideAnim linear infinite;
`;

const ListInstance = styled.div<{ isHovered?: boolean }>`
  display: flex;
  width: max-content;

  animation: slideAnim linear infinite;
  animation-play-state: ${({ isHovered }) =>
    isHovered ? 'paused' : 'running'};
`;

const InfiniteLooper = ({
  speed,
  direction,
  children,
}: {
  speed: number;
  direction: 'right' | 'left';
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [looperInstances, setLooperInstances] = useState(1);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const setupInstances = useCallback(() => {
    if (!innerRef?.current || !outerRef?.current) return;

    const { width } = innerRef.current.getBoundingClientRect();

    const { width: parentWidth } = outerRef.current.getBoundingClientRect();

    const instanceWidth = width / innerRef.current.children.length;

    if (width < parentWidth + instanceWidth) {
      setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
    }
  }, [looperInstances]);

  useEffect(() => {
    setupInstances();
  });

  const handleChildHover = () => {
    setIsHovered(true);
  };

  const handleChildLeave = () => {
    setIsHovered(false);
  };

  const childrenWithHoverListener = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement, {
      onMouseEnter: handleChildHover,
      onMouseLeave: handleChildLeave,
    });
  });

  return (
    <Wrapper className='looper' ref={outerRef}>
      <InnerList className='looper__innerList' ref={innerRef}>
        {[...Array(looperInstances)].map((_, index) => (
          <ListInstance
            key={index}
            className='looper__listInstance'
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === 'right' ? 'reverse' : 'normal',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1.5rem',
            }}
            isHovered={isHovered}
            onMouseEnter={handleChildHover}
            onMouseLeave={handleChildLeave}
          >
            {childrenWithHoverListener}
          </ListInstance>
        ))}
      </InnerList>
    </Wrapper>
  );
};

export default InfiniteLooper;
