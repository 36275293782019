import styled from 'styled-components';
import ProfilePicture from '../landing/components/ProfilePicture';
import Spacer from '../../components/Spacer';
import Divider from '../../components/Divider';
import FadeInBottom from '../../components/motion/FadeInBottom';
import FadeInLeft from '../../components/motion/FadeInLeft';

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  min-height: 100vh;
  scroll-snap-align: start;

  @media (min-width: 768px) {
    margin-top: 64px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
  width: 60vw;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 90vw;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ParagraphText = styled.p`
  color: lightgray;
  max-width: 40vw;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    max-width: 90vw;
  }

  animation-delay: 0.5s;
`;

const Header = styled.h1`
  color: #adff2f;
  font-family: monospace;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div {
    margin-left: 1rem;
  }
`;

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: auto;

  @media (max-width: 768px) {
    height: fit-content;
    width: 100vw;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
  }
`;

function AboutMe({ className }: { className?: string }) {
  return (
    <Outer className={className}>
      <Wrapper>
        <LeftContainer>
          <FadeInLeft
            style={{ width: '100%', height: '100%' }}
            duration={0.5}
            showOnce
            startPos={50}
          >
            <HeaderContainer>
              <Header>About Me</Header>
              <Divider width='55%' />
            </HeaderContainer>
          </FadeInLeft>
          <FadeInBottom
            style={{ width: '100%', height: '100%' }}
            duration={0.5}
            showOnce={true}
            startPos={50}
          >
            <ParagraphText>
              I'm Philip Rutberg, a fullstack web developer from Sweden with a
              passion for programming. I specialize in TypeScript/Nodejs and
              enjoy creating dynamic, user-friendly web applications.
              <br />
              <br />
              As a former lead developer and co-owner of one of Scandinavia's
              largest World of Warcraft boosting communities, I've gained
              valuable experience in managing projects and working
              collaboratively with others. My goal is to continue developing
              innovative solutions that improve the user experience and help
              businesses succeed.
            </ParagraphText>
          </FadeInBottom>
        </LeftContainer>
        <Spacer multiplier={4} />
        <ProfilePictureContainer className='profile-pic-container'>
          <FadeInBottom duration={0.5} startPos={60} showOnce>
            <ProfilePicture />
          </FadeInBottom>
        </ProfilePictureContainer>
      </Wrapper>
    </Outer>
  );
}

export default AboutMe;
