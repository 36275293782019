import { useState } from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transition: all 0.2s ease-in-out;
  path {
    transition: all 0.2s ease-in-out;
  }
`;

const Jest = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledSvg
      width='64px'
      height='64px'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isHovered ? 1 : 0.1 }}
    >
      <g>
        <path
          d='M27.089,15.786a2.606,2.606,0,0,0-2.606-2.606c-.093,0-.184.005-.274.014l3.58-10.557H12.577l3.574,10.548c-.052,0-.105-.005-.158-.005a2.607,2.607,0,0,0-.792,5.09,11.375,11.375,0,0,1-2.049,2.579A10.443,10.443,0,0,1,9.5,23.116a3.324,3.324,0,0,1-1.665-4.23c.077-.18.155-.362.23-.544a2.608,2.608,0,1,0-2.09-.4,20.08,20.08,0,0,0-1.889,4.788c-.354,2.135,0,4.4,1.845,5.681,4.3,2.981,8.969-1.848,13.891-3.061,1.784-.44,3.742-.369,5.313-1.28a4.443,4.443,0,0,0,2.179-3.088,4.639,4.639,0,0,0-.831-3.521,2.6,2.6,0,0,0,.606-1.671ZM18.6,15.8v-.009a2.605,2.605,0,0,0-1.256-2.23L20.188,7.8l2.85,5.814a2.6,2.6,0,0,0-1.161,2.169c0,.019,0,.038,0,.057L18.6,15.8Z'
          fill={isHovered ? '#99425b' : 'darkgray'}
        />
        <path
          d='M27.726,15.786A3.248,3.248,0,0,0,25.083,12.6L28.677,2H11.689l3.6,10.621a3.242,3.242,0,0,0-1.005,5.919,10.852,10.852,0,0,1-1.568,1.846,9.836,9.836,0,0,1-3.16,2.03,2.657,2.657,0,0,1-1.134-3.281l.04-.093.074-.175a3.244,3.244,0,1,0-3.34-.754c-.108.231-.222.465-.339.706a14.022,14.022,0,0,0-1.4,3.8c-.465,2.8.285,5.043,2.111,6.308A5.751,5.751,0,0,0,8.934,30c2.132,0,4.289-1.071,6.4-2.119a23.638,23.638,0,0,1,4.642-1.917,18.262,18.262,0,0,1,1.8-.319,9.969,9.969,0,0,0,3.679-1.028,5.088,5.088,0,0,0,2.487-3.53,5.255,5.255,0,0,0-.69-3.613,3.225,3.225,0,0,0,.475-1.688Zm-1.273,0a1.97,1.97,0,1,1-1.97-1.97,1.973,1.973,0,0,1,1.97,1.97ZM26.9,3.273l-3.174,9.36a3.283,3.283,0,0,0-.4.125l-3.135-6.4L17.046,12.72a3.276,3.276,0,0,0-.419-.114L13.464,3.273ZM15.993,13.816a1.97,1.97,0,1,1-1.97,1.97,1.972,1.972,0,0,1,1.97-1.97Zm-8.523.019A1.97,1.97,0,1,1,5.5,15.805a1.972,1.972,0,0,1,1.969-1.97Zm19.217,7.034a3.818,3.818,0,0,1-1.871,2.646,8.88,8.88,0,0,1-3.223.87c-.632.091-1.285.185-1.924.343a24.609,24.609,0,0,0-4.9,2.013c-3.164,1.571-5.9,2.928-8.472,1.143-1.887-1.308-1.8-3.728-1.58-5.054A12.775,12.775,0,0,1,6,19.376c.092-.19.182-.375.27-.559a3.25,3.25,0,0,0,.838.21,3.963,3.963,0,0,0,2.1,4.655l.245.126.259-.093a11.022,11.022,0,0,0,3.876-2.4,12.11,12.11,0,0,0,1.918-2.32,3.241,3.241,0,0,0,2.64-5.627l2.038-4.121,2.059,4.2a3.24,3.24,0,0,0,4.088,5,3.952,3.952,0,0,1,.355,2.421Z'
          fill={isHovered ? '#fff' : 'lightgray'}
        />
      </g>
    </StyledSvg>
  );
};

export default Jest;
