import { useState } from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transition: all 0.2s ease-in-out;
  path {
    transition: all 0.2s ease-in-out;
  }
`;

const Vuejs = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledSvg
      width='64px'
      height='64px'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isHovered ? 1 : 0.1 }}
    >
      <g>
        <path
          d='M2 4L16 28L30 4H24.5L16 18.5L7.5 4H2Z'
          fill={isHovered ? '#41B883' : 'darkgray'}
        />
        <path
          d='M7.5 4L16 18.5L24.5 4H19.5L16.0653 10.0126L12.5 4H7.5Z'
          fill={isHovered ? '#35495E' : '#35495E'}
        />
      </g>
    </StyledSvg>
  );
};

export default Vuejs;

// import styled from 'styled-components';

// interface Props {
//   filled: string;
// }

// const StyledSvg = styled.svg`
//   path {
//     fill: ${(props: Props) => props.filled};
//   }
// `;

// const Vuejs = ({ filled }: Props) => {
//   return (
//     <StyledSvg
//       width='64px'
//       height='64px'
//       viewBox='0 0 32 32'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//       filled={filled}
//     >
//       <g>
//         <path d='M2 4L16 28L30 4H24.5L16 18.5L7.5 4H2Z' fill='#41B883' />
//         <path
//           d='M7.5 4L16 18.5L24.5 4H19.5L16.0653 10.0126L12.5 4H7.5Z'
//           fill='#35495E'
//         />
//       </g>
//     </StyledSvg>
//   );
// };

// export default Vuejs;
