import styled from 'styled-components';
import Divider from '../../components/Divider';

import InfiniteLooper from './components/InfiniteLooper';
import Nextjs from '../../components/icons/Nextjs';
import Reactjs from '../../components/icons/Reactjs';
import Vuejs from '../../components/icons/Vuejs';
import Html from '../../components/icons/Html';
import Css from '../../components/icons/Css';
import Typescript from '../../components/icons/Typescript';
import Javascript from '../../components/icons/Javascript';
import Nodejs from '../../components/icons/Nodejs';
import Redis from '../../components/icons/Redis';
import Mysql from '../../components/icons/Mysql';
import Mongo from '../../components/icons/Mongo';
import Postgresql from '../../components/icons/Postgresql';
import Figma from '../../components/icons/Figma';
import Spacer from '../../components/Spacer';
import Dynamodb from '../../components/icons/Dynamodb';
import Firebase from '../../components/icons/Firebase';
import Jest from '../../components/icons/Jest';
import Mocha from '../../components/icons/Mocha';
import Github from '../../components/icons/Github';
import Bitbucket from '../../components/icons/Bitbucket';
import Gitlab from '../../components/icons/Gitlab';
import Git from '../../components/icons/Git';
import Docker from '../../components/icons/Docker';
import Kubernetes from '../../components/icons/Kubernetes';
import FadeInBottom from '../../components/motion/FadeInBottom';

import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  gap: 1rem;
  scroll-snap-align: start;

  overflow: hidden;
  white-space: nowrap;

  @media (min-width: 768px) {
    margin-top: 64px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
  width: 60vw;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 100vw;
  }
`;

const Header = styled.h1`
  color: #adff2f;
  font-family: monospace;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    width: 90vw;
    margin-left: 1rem;
  }
`;

const TextBasedSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: auto;
  font-size: 2.5rem;
  text-align: center;
  user-select: none;
  cursor: default;
  font-family: monospace;
  text-transform: uppercase;
  opacity: 0.1;

  color: darkgrey;
  transition: all 0.2s ease-in-out;
  :hover {
    color: #fff;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: inherit;
`;

interface Props {
  className?: string;
}

const Skills = ({ className }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Container className={className}>
      <Wrapper>
        <FadeInBottom
          duration={0.5}
          startPos={75}
          showOnce
          style={{
            width: 'inherit',
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <HeaderContainer>
            <Header>Skills</Header>
            <Divider width='100%' />
          </HeaderContainer>
          <Row>
            {!isMobile && (
              <Divider
                width='1px'
                height='100%'
                color='darkgray'
                style={{
                  opacity: 0.1,
                }}
              />
            )}
            <Wrapper
              style={{
                width: isMobile ? '100vw' : '59vw',
              }}
            >
              <InfiniteLooper direction='left' speed={25}>
                <Spacer multiplier={1} />
                <Html />
                <Css />
                <Javascript />
                <Typescript />
                <Reactjs />
                <Vuejs />
                <Nextjs />
                <Figma />
              </InfiniteLooper>
              <Spacer multiplier={1} />
              <InfiniteLooper direction='right' speed={25}>
                <Spacer multiplier={0.1} />
                <Nodejs />
                <Redis />
                <Mysql />
                <Postgresql />
                <Mongo />
                <Dynamodb />
                <Firebase />
                <Jest />
                <Mocha />
                <TextBasedSkill>Rest</TextBasedSkill>
              </InfiniteLooper>
              <Spacer multiplier={1} />
              <InfiniteLooper direction='left' speed={25}>
                <Spacer multiplier={1} />
                <Github />
                <Bitbucket />
                <Gitlab />
                <Git />
                <Docker />
                <Kubernetes />
              </InfiniteLooper>
            </Wrapper>
            {!isMobile && (
              <Divider
                width='1px'
                height='100%'
                color='darkgray'
                style={{
                  opacity: 0.1,
                }}
              />
            )}
          </Row>
        </FadeInBottom>
      </Wrapper>
    </Container>
  );
};

export default Skills;
